/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "consult-with-us-for-the-right-fit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#consult-with-us-for-the-right-fit",
    "aria-label": "consult with us for the right fit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Consult with Us for the Right Fit"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You ears have a distinctive shape that some scientists have researched as a possible substitute for fingerprints in identification. Likewise, your hearing loss and the challenges you face are unique to you. Therefore, you deserve to work with a hearing care professional who will customize a hearing technology solution for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For Unitron Insera ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing device"), ", your hearing care professional will make a mold of your ears and send it to the ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid manufacturer"), ". Highly trained technicians make a hearing aid shell and design the components to fit inside the shell. As a result, you’ll enjoy a comfortable and discreet fit."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing care professional will help you choose among the available form factors for Unitron Insera. These hearing aids come in five sizes:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Full shell: These cover the entire bowl part of your outer ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Canal/Half shell: These cover part of the bowl part of your outer ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Mini Canal: These are a bit smaller than canal hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Completely in the Canal (CIC): These cover just the ear canal opening."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Invisible in Canal (IIC): When fully inserted, these hearing aids are not visible at all."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The right choice for you will depend on your hearing loss, your ear canal anatomy, your lifestyle, your susceptibility to ear wax, your visual acuity, and your manual dexterity."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "sophisticated-technology-works-hard-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sophisticated-technology-works-hard-for-you",
    "aria-label": "sophisticated technology works hard for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sophisticated Technology Works Hard for You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with hearing loss find noisy environments especially challenging. Unitron’s engineers have designed Insera hearing aids to process sound in such a way that speech sounds clearer and noise becomes less bothersome."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Directional microphone technology selectively pick up sounds coming from one direction. If the person you want to talk to is in front of you, you want the hearing aids to suppress sounds coming from directions other than the front. Unitron’s multiband adaptive technology works to accurately locate the speaker and noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Noise doesn’t just interfere with understanding speech. It also makes you tired and annoyed. Unitron’s noise reduction system dampens the noise component of the audio signal to make listening more comfortable for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sudden sharp sounds like a hammer or dishes clanging against each other can sound quite unpleasant with conventional hearing aids. The AntiShock 2 in Unitron aids act quickly to smooth out these sounds for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The wind control feature reduces the annoyance of wind brushing up against the hearing aid microphones when you’re outside on the golf course on a blustery day.\nUnitron also has a dedicated automatic music program in the hearing aids that preserves the fullness and richness of music. Conventional hearing aid settings focus on speech understanding. Unfortunately, the programming that assists with speech understanding can cause distortions in music. The Unitron technology switches processing modes when it detects music."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Depending on the technology level that you choose to purchase, your Unitron Insera aids will have up to seven different modes. The SoundNav on the digital chip automatically identifies which of the seven modes are appropriate for your current listening environment. If you want more control over the sound, you can adjust the volume and programs through an app on your phone or through buttons on the aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "technology-flexible-enough-to-work-with-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#technology-flexible-enough-to-work-with-you",
    "aria-label": "technology flexible enough to work with you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Technology Flexible Enough to Work with You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you suffer from ringing in your ears (tinnitus), your hearing care professional can add a tinnitus masker to your programs. This is a pleasant, ongoing sound that is designed to make your tinnitus less bothersome."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As you wear your hearing aids, you’ll be able to rate your experience in the app. Your hearing care professional will be able to use this information, along with daily data logging in the aids, to make better fine tuning adjustments during your follow-up visits."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rest-easy-with-hearcom-at-your-side",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rest-easy-with-hearcom-at-your-side",
    "aria-label": "rest easy with hearcom at your side permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rest Easy With hear.com at Your Side"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s so much conflicting information about hearing loss and hearing aids on the internet. With hear.com, you won’t face your hearing journey alone. After you sign up online for our 45 day risk-free trial, one of our experts will reach out to you and answer your questions. We’ll refer you to a local Partner Provider for a comprehensive evaluation. The hearing care professional will make a recommendation customized to you. If you decide to proceed, the hearing care professional will fit you with the hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Along the entire way, our expert will monitor your progress. You can call us anytime for guidance. We will even help you find financing solutions. You will have 45 days to try out your new hearing aids. If you are not satisfied, you can return the devices for a full refund."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Unitron Insera now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
